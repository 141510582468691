import image1999 from '../assets/images/our-history/1999.png';
import image2008 from '../assets/images/our-history/2008.png';
import image2015 from '../assets/images/our-history/2015.png';
import image2016 from '../assets/images/our-history/2016.png';
import image2018 from '../assets/images/our-history/2018.png';
import image2019 from '../assets/images/our-history/2019.png';
import image2020 from '../assets/images/our-history/2020.png';
import image2021 from '../assets/images/our-history/2021.png';

export const history = [
  // {
  //   year: '1999',
  //   title: 'A 20 year history and we are just getting started.',
  //   body: 'In 1999 our founders set up Aqua, the first full-service digital agency in South Africa',
  //   image: image1999,
  // },
  // { 
  //   year: '2008',
  //   title: 'Our second base',
  //   body: '2008 sees the launch of Base 2, a second niche service agency',
  //   image: image2008,
  // },
  {
    
    year: '2014',
    title: 'The beginning of Beard',
    body: 'Lucky Beard Pretoria launches in 2014, specialising in digital product development, brand strategy and channel strategy',
    image: image2008,
  },
  {
    year: '2016',
    title: 'We open in Joburg',
    body: 'Our Joburg office opens its doors in 2016',
    image: image2016,
  },
  {
    year: '2017',
    title: 'Significant Beard growth - we launch in Ireland',
    body: 'In 2017 Enterprise Ireland sponsor us to open an office in Dublin and bring our work to the Irish market, now offering strategic advsory services, and brand, product and experience design.',
    image: image2018,
  },
  {
    year: '2018',
    title: 'Our London office opens',
    body: 'Lucky Beard London begins operating in 2018',
    image: image2019,
  },
  {
    year: '2020',
    title: 'Lucky Beard Stories',
    body: 'Our strength at Lucky Beard is building products, experiences, brands and businesses that we believe in. Our philosphy is that if you have a good product that customers love and want to use, marketing it becomes easy. In 2020 we aquired 50% of SA born Production house, 10th Street Media with the aim to give a voice to the brands we work with and an ongoing conversation with customers using it.',
    image: image2020,
  },
  {
    year: '2021',
    title: 'Lucky Beard Labs',
    body: 'Whenever we start work with any partner, we do intensive market intel to form our design thinking. We rigorously run user testing to optimise the product before it gets to market . Ultimately, the only voice that matters is the customer’s voice. Lucky Beard Labs focusses purely on R&D and testing',
    image: image1999,
  },
];
