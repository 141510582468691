// extracted by mini-css-extract-plugin
export var ourHistory = "index-module--our-history--VO3Bl";
export var topContainer = "index-module--top-container--SoFyl";
export var carouselWrapper = "index-module--carousel-wrapper--0wMlk";
export var swiperContainer = "index-module--swiper-container--dK84T";
export var swiperSlide = "index-module--swiper-slide--zyjdD";
export var historySlide = "index-module--history-slide--8+5LB";
export var buttonPrev = "index-module--button-prev--8yDyV";
export var buttonNext = "index-module--button-next--MvSKm";
export var hidden = "index-module--hidden--9WXOj";
export var paginationWrapper = "index-module--pagination-wrapper--KoxeI";
export var paginationItem = "index-module--pagination-item--fhsKG";
export var active = "index-module--active--Iinfz";
export var paginationText = "index-module--pagination-text--2OxHk";
export var paginationLine = "index-module--pagination-line--LCstr";
export var bottomContainer = "index-module--bottom-container--q41w6";
export var inner = "index-module--inner--GxUSm";
export var ourStorySoFar = "index-module--our-story-so-far--WQ1jv";
export var leftContainer = "index-module--left-container--vErH5";
export var rightContainer = "index-module--right-container--nNpD7";