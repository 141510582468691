import React, { useEffect, useState, useRef, forwardRef } from 'react';
import axios from 'axios';
import { StaticImage } from 'gatsby-plugin-image';

import * as styles from './index.module.scss';
import ScrollablePage from '../../layouts/scrollable-page';
import Slogan from '../../components/slogan';
import Arrow from '../../components/arrow';
import OurHistory from '../../components/our-history';
import Button from '../../components/button';
import BuildTransformOptimise from '../../components/build-transform-optimise';
import ScrollablePageHeader from '../../components/scrollable-page-header';
import Footer from '../../components/footer';
import { history } from '../../constants/history';

// IMPORTING IMAGES
import cube from '../../assets/images/shapes/topdown-renders/cube.png';
import sphere from '../../assets/images/shapes/topdown-renders/sphere.png';
import dollos from '../../assets/images/shapes/topdown-renders/dollos.png';
import torus from '../../assets/images/shapes/topdown-renders/torus.png';

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import WhatWeDoSection from '../../components/what-we-do-section';
import WorkWithUs from '../../components/work-with-us';
import SEO from '../../components/seo';

import { useDispatch, useSelector } from 'react-redux';
import { getCaseStudies } from '../../helpers/axios';
import { setCaseStudies } from '../../state/caseStudies';
import useImagePreloader from '../../hooks/useImagePreloader';
import Preloader from '../../components/preloader';
import whatWeDoData from '../../constants/whatWeDo';
import { scrollableHeaderLandingAnimation } from '../../animations/hero';

import {
  areasOfExpertiseAnimation,
  phraseAndShapeAnimation,
  ourHistoryAnimation,
} from '../../animations/what-we-do';
const WhatWeDo = () => {
  const caseStudies = useSelector((state) => state.caseStudies.caseStudies);
  const scrollablePageRef = useRef();
  const rootRef = useRef();

  const imagePreloader = useImagePreloader([
    // blur,
    // blur2,
    cube,
    sphere,
    dollos,
    torus,
    ...caseStudies.map((cs) => cs.attributes.featureImage.data.attributes.url),
  ]);

  const dispatch = useDispatch();
  const fetchCaseStudies = async () => {
    const response = await getCaseStudies();
    dispatch(setCaseStudies(response.data));
  };

  useEffect(() => {
    if (caseStudies.length === 0) {
      fetchCaseStudies();
      return;
    }
    imagePreloader.startPreloading();
  }, [caseStudies]);

  const getRelevantCaseStudies = (slugs) => {
    if (slugs) {
      return caseStudies.filter((item) => slugs.includes(item.attributes.slug));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (imagePreloader.isLoading) return;
    scrollableHeaderLandingAnimation(rootRef);

    // init scrub animation
    areasOfExpertiseAnimation(rootRef, scrollablePageRef);
    phraseAndShapeAnimation(rootRef, scrollablePageRef);
    ourHistoryAnimation(rootRef, scrollablePageRef);
  }, [imagePreloader.isLoading]);

  return (
    <ScrollablePage simpleBarRef={scrollablePageRef}>
      <SEO
        title={'Lucky Beard | Global Design and Advisory | What we do'}
      />

      {imagePreloader.isLoading && <Preloader />}

      <div ref={rootRef} className={`${styles.whatWeDoWrapper}`}>
        <ScrollablePageHeader
          scrollablePageRef={scrollablePageRef}
          subHeading={'What we do'}
          heading={'We make things that change things. Quickly.'}
          description={
            'We are a global design and advisory company. We partner with restless leadership teams, helping them to build businesses, brands, products and experiences for the customers of tomorrow.'
          }
          description2={
            'Our mission is to create a home where exceptional talent can make a meaningful difference.'
          }
          images={{ shape1: cube, shape2: sphere, shape3: dollos }}
        />

        {whatWeDoData &&
          whatWeDoData.map((item, index) => (
            <div key={item.title} className='wwds'>
              <WhatWeDoSection
                data={item}
                relevantCaseStudies={getRelevantCaseStudies(
                  item.caseStudySlugs
                )}
              />{' '}
            </div>
          ))}

        <h1 className={`main-description title-XL ${styles.twentyYears}`}>
          <div className='d'>
            HELPING STARTUPS BECOME BRANDS, AND BRANDS BECOME STARTUPS.
          </div>
          <div className={`d-line ${styles.line}`} />
          <img alt="A topdown render of a sphere shape" className={`d-sphere ${styles.tySphere}`} src={sphere} />
        </h1>

        <OurHistory history={history} />

        <WorkWithUs bgColor={'#f9f9fa'} shape={torus} />

        <Footer />
      </div>
    </ScrollablePage>
  );
};

export default WhatWeDo;
