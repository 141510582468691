import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import { Transition } from 'react-transition-group';

import { StaticImage } from 'gatsby-plugin-image';
import SwiperCore, { Autoplay } from 'swiper';
import gsap, { Sine } from 'gsap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css';

import chevronRight from '../../assets/images/chevron-right-dark.svg';
import chevronLeft from '../../assets/images/chevron-left-dark.svg';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// const

const OurHistory = ({ history }) => {
  const breakpoints = useBreakpoint();
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef();
  const swiperInstance = useRef();
  const changeActiveIndex = (index) => {
    setActiveIndex(index);
  };
  const gotoNextActiveIndex = () => {
    if (activeIndex < history.length) {
      const newIndex = activeIndex + 1;
      setActiveIndex(newIndex);
    }
  };
  const gotoPreviousActiveIndex = () => {
    if (activeIndex >= 0) {
      const newIndex = activeIndex - 1;
      setActiveIndex(newIndex);
    }
  };

  const offsetSlider = (swiper) => {
    gsap.to(swiperRef.current.firstChild, {
      marginLeft: swiper.slidesGrid[0],
      duration: 0.0001,
    });
  };

  useEffect(() => {
    swiperInstance.current.slideTo(activeIndex);
  }, [activeIndex]);

  const sliderSettings = {
    slidesPerView: 1,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    speed: 650,
    draggable: false,
    autoplay: {
      delay: 5000,
    },
  };

  return (
    <div className={`oh ${styles.ourHistory}`}>
      <div className={styles.topContainer}>
        <div className={styles.carouselWrapper}>
          <Swiper
            navigation
            modules={[EffectFade, Navigation]}
            effect='fade'
            className={`${styles.swiperContainer}`}
            onInit={(val) => {
              swiperInstance.current = val;
            }}
            onTransitionStart={(swp) => {
              setActiveIndex(swp.activeIndex);
            }}
            onResize={offsetSlider}
            onSwiper={offsetSlider}
            ref={swiperRef}
            {...sliderSettings}
          >
            {history.map((item) => (
              <SwiperSlide
                key={`carousel-item-${item.year}`}
                className={`${styles.swiperSlide}`}
              >
                <div
                  className={styles.historySlide}
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                ></div>
                {/* <img src={item.image} alt='lucky beard history' /> */}
              </SwiperSlide>
            ))}

            <div
              className={`button-prev ${styles.buttonPrev} ${
                activeIndex === 0 && styles.hidden
              }`}
            >
              <img
                alt='A dark chevron pointing towards the left'
                src={chevronLeft}
              />
            </div>
            <div
              className={`button-next ${styles.buttonNext} ${
                activeIndex === history.length - 1 && styles.hidden
              }`}
            >
              <img
                alt='A dark chevron pointing towards the right'
                src={chevronRight}
              />{' '}
            </div>
          </Swiper>
        </div>

        <div className={`${styles.paginationWrapper}`}>
          {history &&
            history.map((item, index) => (
              <div
                key={`history-item-${item.year}`}
                className={`${styles.paginationItem} ${
                  activeIndex === index ? styles.active : ''
                }`}
              >
                <span
                  key={item.year}
                  className={`utility-noXS ${styles.paginationText} `}
                  onClick={() => {
                    changeActiveIndex(index);
                  }}
                >
                  {!breakpoints.smd
                    ? item.year
                    : item.year.substring(
                        item.year.length - 2,
                        item.year.length
                      )}
                </span>
                {!breakpoints.smd && (
                  <div className={`${styles.paginationLine}`} />
                )}
              </div>
            ))}
        </div>
      </div>

      <div className={styles.bottomContainer}>
        {history.map((item, index) => (
          <Transition
            key={`transition-item-${item.year}`}
            timeout={450}
            mountOnEnter
            unmountOnExit
            in={index === activeIndex}
            onEnter={(node) => {
              gsap.from(node.querySelectorAll('.text'), {
                duration: 0.45,
                opacity: 0,
                stagger: 0.03,
                y: 30,
                clearProps: 'opacity',
              });
            }}
            onExit={(node) => {
              gsap.set(node, {
                position: 'absolute',
                top: 0,
                bottom: 0,
              });
              gsap.to(node.querySelectorAll('.text'), {
                duration: 0.25,
                y: -10,
                opacity: 0,
                clearProps: 'position',
              });
            }}
          >
            <div className={`${styles.inner}`}>
              <div className={`${styles.leftContainer}`}>
                <h3 className={'utility-noM text'}>{item.year}</h3>
              </div>

              <div className={styles.rightContainer}>
                <h5 className={'title-S text'}>{item.title}</h5>
                <hr className={'text'} />
                <p className={'body-S text'}>{item.body}</p>
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
};

export default OurHistory;
