import gsap, { Power4 } from 'gsap';
import { isMobile } from 'react-device-detect';
const areasOfExpertiseAnimation = (node, scrollBarRef) => {
  if (!node || !node.current || !scrollBarRef || !scrollBarRef.current) return;
  const duration = 1;
  const easing = Power4.easeOut;
  node.current.querySelectorAll('.wwds').forEach((item) => {
    // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: item,
        start: `top ${isMobile ? '70%' : '40%'}`,
        scroller: scrollBarRef.current,
      },
    });

    const tBlock = gsap.from(item.querySelectorAll('.copy'), {
      duration,
      ease: easing,
      x: -10,
      stagger: 0.03,
      opacity: 0,
    });

    const tCarousel = gsap.from(item.querySelectorAll('.cw .swiper-slide'), {
      y: 20,
      ease: easing,
      duration,
      opacity: 0,
      stagger: 0.1,
    });

    tl.add(tBlock, 0);
    tl.add(tCarousel, 0.4);
  });
};

const phraseAndShapeAnimation = (node, scrollBarRef) => {
  if (!node || !node.current) return;
  const duration = 1;
  const easing = Power4.easeOut;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.main-description',
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
    },
  });

  const tBlock = gsap.from(node.current.querySelector('.main-description .d'), {
    duration,
    ease: easing,
    y: -20,
    // stagger:0.03,
    opacity: 0,
  });

  const tLine = gsap.from(node.current.querySelector('.d-line'), {
    width: 0,
    duration: duration,
    ease: easing,
    opacity: 0,
  });

  const tShape = gsap.from(node.current.querySelector('.d-sphere'), {
    x: 100,
    opacity: 0,
    duration: duration,
    clearProps: 'all',
    ease: easing,
  });

  tl.add(tBlock, 0);
  tl.add(tLine, 0);
  tl.add(tShape, 0.2);
};

const ourHistoryAnimation = (node, scrollBarRef) => {
  if (!node || !node.current) return;
  const duration = 1;
  const easing = Power4.easeOut;
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.oh',
      start: `top ${isMobile ? '70%' : '40%'}`,
      scroller: scrollBarRef.current,
    },
  });
  const ourHistory = gsap.from(node.current.querySelector('.oh'), {
    duration,
    ease: easing,
    opacity: 0,
  });

  tl.add(ourHistory, 0);
};

export {
  areasOfExpertiseAnimation,
  phraseAndShapeAnimation,
  ourHistoryAnimation,
};
