// extracted by mini-css-extract-plugin
export var whatWeDoWrapper = "index-module--what-we-do-wrapper--UBGCi";
export var shape = "index-module--shape--DBE4E";
export var blur = "index-module--blur---tebH";
export var blur2 = "index-module--blur2--QPiXy";
export var cube = "index-module--cube--lPUii";
export var ovoid = "index-module--ovoid--Q86-q";
export var dollos = "index-module--dollos--ujfZ-";
export var sloganWrapper = "index-module--slogan-wrapper--m+4zO";
export var whatWeDoHeader = "index-module--what-we-do-header--GTFWi";
export var arrowWrapper = "index-module--arrow-wrapper--yNkuG";
export var introContent = "index-module--intro-content--yoZWJ";
export var buildTransformOptimiseContentContainer = "index-module--build-transform-optimise-content-container--yGJLA";
export var main = "index-module--main--AmajN";
export var twentyYears = "index-module--twenty-years--kTiD4";
export var tySphere = "index-module--ty-sphere--ZrDMH";
export var line = "index-module--line--cBxtx";
export var workWithUs = "index-module--work-with-us--Mirtx";
export var contentWrapper = "index-module--content-wrapper--AeqLW";
export var testimonyCarousel = "index-module--testimony-carousel--xoU65";
export var swiperWrapper = "index-module--swiper-wrapper--G2zbU";
export var swiperSlide = "index-module--swiper-slide--XOJo8";
export var testimonyBlockWrapper = "index-module--testimony-block-wrapper--53p45";
export var testimonyBlockWipe = "index-module--testimony-block-wipe--3V62c";
export var testimonyBlock = "index-module--testimony-block--uvAQn";
export var logoWrapper = "index-module--logo-wrapper--525UO";