import React, { useEffect, useState, useRef } from 'react';
import gsap from 'gsap';
import * as styles from './index.module.scss';

import WipeLink from '../../nav-transitions/wipe-link';

import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const CarouselBasic = ({ relevantCaseStudies }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperRef = useRef();
  const swiperInstance = useRef();

  const sliderSettings = {
    slidesPerView: 'auto',
    spaceBetween: 35,
    // centeredSlides: true,
    grabCursor: true,
    autoplay: {
      disableOnInteraction: false,
      delay: 50000,
    },
    onSlideChange: (e) => {
      setActiveIndex(e.realIndex);
    },
  };

  const offsetSlider = (swiper) => {
    // gsap.to(swiperRef.current.firstChild, {
    //   marginLeft: swiper.slidesGrid[0],
    //   duration: 0.0001,
    // });
  };

  SwiperCore.use([Autoplay]);

  return (
    <div className={styles.carouselBasic}>
      <div className={styles.swiperWrapper}>
        <Swiper
          className={`${styles.swiperContainer}`}
          onInit={(val) => {
            swiperInstance.current = val;
          }}
          onResize={offsetSlider}
          onSwiper={offsetSlider}
          ref={swiperRef}
          // style={{ paddingLeft: 150, paddingRight: 150 }}
          {...sliderSettings}
        >
          {relevantCaseStudies &&
            relevantCaseStudies.map((brand, i) => (
              <SwiperSlide
                key={brand.attributes.slug}
                className={`${styles.swiperSlide}`}
                style={{
                  backgroundImage: `url('${brand.attributes.featureImage.data.attributes.url}')`,
                }}
              >
                <WipeLink
                  to={`/our-work/${brand.attributes.slug}`}
                  key={brand.attributes.slug}
                >
                  <div className={`${styles.clicker}`}></div>
                </WipeLink>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CarouselBasic;
