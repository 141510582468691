const whatWeDoData = [
    {
      title: 'Business design',
      subtitle: 'We discover new opportunities for growth',
      body: 'We help build businesses from the ground up, or we transform, accelerate and optimise what’s in place today to ensure you are fit to compete – and win – tomorrow. Our rigourous process, delivered at pace, enables us to uncover refreshing insights and perspectives that shape an exciting path forward.',
      tags: [
        {
          name: 'As-is Analysis',
          highlighted: true,
        },
        {
          name: 'Market Intelligence',
          highlighted: true,
        },
        {
          name: 'Business Strategy',
          highlighted: true,
        },
        {
          name: 'Gap & Opportunity Identification',
          highlighted: true,
        },
        {
          name: 'Strategic Advisory & Delivery Plan',
          highlighted: true,
        },
        {
          name: 'Stakeholder Engagement',
          highlighted: false,
        },
        {
          name: 'Tech Audits',
          highlighted: true,
        },
        {
          name: 'Investment Support / Venture Decks',
          highlighted: true,
        },
      ],
      caseStudySlugs: ['vodapay', 'aryza', '123ie'],
    },
    {
      title: 'Brand design',
      subtitle: 'We build brands that people can believe in',
      body: 'In a world that doesn\'t suffer from a shortage of options, we help create simple, clear, conscise and compelling brands that really matter.',
      tags: [
        {
          name: 'Company Vision & Purpose',
          highlighted: true,
        },
        {
          name: 'Brand Positioning & Strategy',
          highlighted: true,
        },
        {
          name: 'Brand Architecture',
          highlighted: true,
        },
        {
          name: 'Brand Identity',
          highlighted: true,
        },
        {
          name: 'Design Systems',
          highlighted: false,
        },
        {
          name: 'Collateral Design',
          highlighted: true,
        },
        {
          name: 'Tone of Voice',
          highlighted: true,
        },
      ],
      caseStudySlugs: [
        'the-gloss',
        'mccauley',
        'more',
        'investec',
        'vodapay',
        'sharejoy'
      ],
    },
    {
      title: 'Proposition design',
      subtitle: 'We design products that people couldnt imagine living without',
      body: 'In an increasingly competitive environment, people’s tolerance for lacklustre products and experiences is diminishing fast. We help imagine, design, scope, build and test new propositions that make a difference.',
      tags: [
        {
          name: 'Pain Point Mapping',
          highlighted: true,
        },
        {
          name: 'Customer Needs Analysis',
          highlighted: true,
        },
        {
          name: 'Customer Value Propositions',
          highlighted: true,
        },
        {
          name: 'Product Scope and Feature Set Definition',
          highlighted: false,
        },
        {
          name: 'Roadmaps',
          highlighted: true,
        },
        {
          name: 'Release Plans',
          highlighted: true,
        },
      ],
      caseStudySlugs: ['123ie', 'investec', 'iob', 'vodapay'],
    },
    {
      title: 'Experience design',
      subtitle: 'We create experiences that delight people',
      body: 'This is where the rubber hits the road. The moment of truth for any business, and often the biggest competitive advantage or shortcoming. It’s where companies build trust, delight, loyalty and love... or maybe fall short, and leave people feeling underwhelmed. We help to create experiences that bring a little joy.',
      tags: [
        {
          name: 'CX',
          highlighted: true,
        },
        {
          name: 'UX',
          highlighted: true,
        },
        {
          name: 'UI',
          highlighted: true,
        },
        {
          name: 'Personas',
          highlighted: true,
        },
        {
          name: 'Journey Maps',
          highlighted: true,
        },
        {
          name: 'User Stories',
          highlighted: true,
        },
        {
          name: 'Wireframe Design',
          highlighted: true,
        },
        {
          name: 'Interaction Design',
          highlighted: true,
        },
        {
          name: 'Prototyping',
          highlighted: true,
        },
        {
          name: 'Testing, Analysis and Optimisation',
          highlighted: true,
        },
      ],
      caseStudySlugs: [
        'irish-life',
        'vodapay',
        'outsurance',
        'thrive',
      ],
    },
    {
      title: 'Lucky Beard Stories',
      subtitle: 'We solve problems with stories',
      body: 'Attention is one of the rarest and most valuable resources on the planet. We strive to create content worthy of people’s attention because we know that when you deliver the right content at the right time and in the right context, it is a powerful driver of change.',
      tags: [
        {
          name: 'Content Design',
          highlighted: true,
        },
        {
          name: 'Content Strategy',
          highlighted: true,
        },
        {
          name: 'Multimedia Production',
          highlighted: true,
        },
        {
          name: 'Internal and External',
          highlighted: true,
        },
        {
          name: 'Change Management Comms',
          highlighted: true,
        },
      ],
      caseStudySlugs: [],
    },
    {
      title: 'Lucky Beard Labs',
      subtitle: 'We listen to the people who matter most',
      body: 'Our ability to listen is correlated with our ability to change, evolve  and grow. We immerse ourselves in what your customers, people and communities are saying, doing and feeling, so that we can figure out how to make their lives better, and your business more relevant.',
      tags: [
        {
          name: 'Proposition Testing',
          highlighted: true,
        },
        {
          name: 'Usability Testing',
          highlighted: true,
        },
        {
          name: 'Optimisation',
          highlighted: true,
        },
        {
          name: 'Data & Analytics',
          highlighted: true,
        },
        {
          name: 'Pain Point Mapping',
          highlighted: true,
        },
        {
          name: 'Product Market Fit',
          highlighted: true,
        },
        {
          name: 'Qualitative',
          highlighted: true,
        },
        {
          name: 'Quantitative',
          highlighted: true,
        },
      ],
      caseStudySlugs: [],
    },
  ];

  export default whatWeDoData;