import React, { useEffect, useState, useRef, forwardRef } from 'react';
import CarouselBasic from '../carousels/carousel-basic';
import * as styles from './index.module.scss';

const WhatWeDoSection = ({ data, relevantCaseStudies }) => {
  return (
    <div className={`${styles.whatWeDoSection}`}>
      <div className={`${styles.whatWeDoSectionContent}`}>
        <div className={`${styles.containerTop}`}>
          <div className={`${styles.containerLeft}`}>
            <h2 className={`copy title-M ${styles.title}`}>{data.title}</h2>
            <p className={`copy body-M ${styles.subtitle}`}>{data.subtitle}</p>
          </div>
          <div className={`${styles.containerRight}`}>
            {data.tags &&
              data.tags.map((item, index) => (
                <p
                  key={item.name}
                  className={`copy body-XS ${item.highlighted ? 'highlighted' : ''}`}
                >
                  {item.name}
                </p>
              ))}
          </div>
        </div>
        <div className={`${styles.containerBottom}`}>
          <p className={`copy body-S ${styles.body}`}>{data.body}</p>
        </div>
      </div>

      {relevantCaseStudies && (
        <div className={`cw ${styles.carouselWrapper}`}>
          {' '}
          <CarouselBasic relevantCaseStudies={relevantCaseStudies} />{' '}
        </div>
      )}
    </div>
  );
};

export default WhatWeDoSection;
